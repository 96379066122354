import React from "react";
import * as d3 from "d3";
import Sidebar from "../sidebar/sidebar";
import Button from "../main/button";
import colors from "../../App.scss";
import randomIntFromInterval from "../../utils/utils";
import data from "../data/bar.csv";

const t = d3.transition().duration(750);
const canvasHeight = 300;
const canvasWidth = 600;

const xScaleGenerator = data =>
  d3
    .scaleBand()
    .domain(d3.range(data.length))
    .rangeRound([0, canvasWidth])
    .paddingInner(0.05);
const yScaleGenerator = data =>
  d3
    .scaleLinear()
    .domain([0, d3.max(data)])
    .rangeRound([0, canvasHeight - 30]);

class Bar extends React.Component {
  state = {
    currentData: this.generateRandomData()
  };

  componentDidMount() {
    d3.csv(data)
      .then(function(data) {
        const thing = data.map(data => +data.value);
        return thing;
      })
      .then(thing => this.mountChart(thing));
  }

  componentDidUpdate() {
    this.updateChart(this.state.currentData);
  }

  componentWillUnmount() {
    // clearInterval(this.interval);
  }

  updateData = () => {
    this.setState({ currentData: this.generateRandomData() });
  };

  generateRandomData() {
    return Array.from({ length: randomIntFromInterval(10, 20) }, () =>
      Math.floor(Math.random() * randomIntFromInterval(10, 1000))
    );
  }

  updateChart(data) {
    const canvas = d3.select(this.refs.canvas);
    const xScale = xScaleGenerator(data);
    const yScale = yScaleGenerator(data);

    canvas.selectAll("*").interrupt(); // interrupt for when we click the button too many times.

    let bars = canvas
      .select("svg")
      .selectAll("rect")
      .data(data);
    let texts = canvas
      .select("svg")
      .selectAll("text")
      .data(data);

    // Exit selection.
    bars
      .exit()
      .transition(t)
      .attr("y", canvasHeight)
      .remove();
    texts
      .exit()
      .transition(t)
      .attr("y", (d, i) => 0)
      .remove();
    // Enter selection.
    bars
      .enter()
      .append("rect")
      .attr("fill", colors.primary)
      .attr("width", xScale.bandwidth())
      .attr("height", d => yScale(d))
      .attr("x", (d, i) => xScale(i) + 600)
      .attr("y", (d, i) => canvasHeight - yScale(d))
      .transition(t)
      .attr("x", (d, i) => xScale(i));
    texts
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", (d, i) => xScale(i) + xScale.bandwidth() / 2)
      .attr("y", (d, i) => 0)
      .text(d => d)
      .transition(t)
      .attr("y", (d, i) => canvasHeight - yScale(d) - 5);
    // Update selection.
    bars
      .transition(t)
      .attr("width", xScale.bandwidth())
      .attr("height", d => yScale(d))
      .attr("x", (d, i) => xScale(i))
      .attr("y", (d, i) => canvasHeight - yScale(d));
    texts
      .transition(t)
      .attr("x", (d, i) => xScale(i) + xScale.bandwidth() / 2)
      .attr("y", (d, i) => canvasHeight - yScale(d) - 5)
      .text(d => d);
  }

  mountChart(data) {
    const xScale = xScaleGenerator(data);
    const yScale = yScaleGenerator(data);

    this.setState({ currentData: data });

    const canvas = d3
      .select(this.refs.canvas)
      .append("svg")
      .attr("viewBox", `0 0 ${canvasWidth} ${canvasHeight}`)
      .style("border", "1px dashed " + colors.primary);

    canvas
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("fill", colors.primary)
      .attr("width", xScale.bandwidth())
      .attr("height", d => yScale(d))
      .attr("x", (d, i) => xScale(i))
      .attr("y", canvasHeight)
      .transition(t)
      .attr("y", (d, i) => canvasHeight - yScale(d));

    canvas
      .selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("x", (d, i) => xScale(i) + xScale.bandwidth() / 2)
      .attr("y", (d, i) => 0)
      .text(d => d)
      .transition(t)
      .attr("y", (d, i) => canvasHeight - yScale(d) - 5);
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="container">
            <h1 className="title">Bar</h1>
            <p className="content">
              The chart below loads a 10 data point <code>csv</code> on mount
              and will update with transitions when{" "}
              <Button onClickHandler={this.updateData} /> is clicked.
            </p>
            <div className="columns box">
              <div className="column is-2 has-text-centered">
                <Sidebar
                  sideBarLabel="Number of bars"
                  text={this.state.currentData.length}
                />
              </div>
              <div className="column is-centered" ref="canvas"></div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Bar;
