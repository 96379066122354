import React from "react";
import * as d3 from "d3";
import colors from "../../App.scss";
import data from "../data/australian_open_singles_results.csv";

const margin = { top: 60, right: 40, bottom: 30, left: 60 },
  canvasWidth = 1200 - margin.left - margin.right,
  canvasHeight = 600 - margin.top - margin.bottom,
  jitterWidth = 40,
  jitterHeight = jitterWidth / 2;

const t = d3.transition().duration(500);

function xScaleGenerator(data, key) {
  let extent = [...new Set([...data.map(d => d[key])])];
  extent.sort();
  const scaler = d3
    .scalePoint()
    .domain(extent)
    .range([0, canvasWidth])
    .padding(0.5);
  return scaler;
}

const yScaleGenerator = (data, key) =>
  d3
    .scaleLinear()
    .domain([-1, 1])
    .range([canvasHeight, 0]);

const colorScaleGenerator = data =>
  d3
    .scaleOrdinal()
    .domain(data)
    .range(data.map((val, i) => d3.interpolatePlasma(i / (data.length - 1))));

const selectedPlayers = [
  "roger_federer",
  "novak_djokovic",
  "rafael_nadal",
  "andy_murray",
  "serena_williams"
];

const selectPlayerYear = [
  "roger_federer_2018",
  "novak_djokovic_2019",
  "rafael_nadal_2017",
  "andy_murray_2016",
  "serena_williams_2010"
];

class Tennis extends React.Component {
  state = {
    currentDate: ""
  };

  componentDidMount() {
    d3.csv(data)
      .then(function(data) {
        data.forEach(function(d) {
          d.year = +d.date.substring(0, 4);
          d.isNight = d.time === "night" ? 1 : 0;
          d.isDay = d.time === "day" ? 1 : 0;
        });

        // unique players.
        const players = [
          ...new Set([
            ...data.map(match => match.winner),
            ...data.map(match => match.loser)
          ])
        ];
        let summary = [];
        players.forEach(function(target) {
          let playerMatches = data.filter(
            d => d.winner === target || d.loser === target
          );

          let y = playerMatches.reduce((acc, match) => {
            const index = acc.findIndex(i => {
              return i.year === match.year;
            });

            if (index > -1) {
              acc[index].nightMatches += match.isNight;
              acc[index].dayMatches += match.isDay;
              acc[index].totalMatches += match.isNight + match.isDay;
            } else {
              acc.push({
                player: target,
                year: match.year,
                nightMatches: match.isNight,
                dayMatches: match.isDay,
                totalMatches: match.isNight + match.isDay
              });
            }
            return acc;
          }, []);

          y.forEach(function(d) {
            d.nightMatchesPercentage = d.nightMatches / d.totalMatches;
            d.dayMatchesPercentage = d.dayMatches / d.totalMatches;
          });

          summary.push(...y);
        });
        return summary;
      })
      .then(data => this.mountChart(data));
  }

  handleMouseOver(d, i) {
    d3.selectAll("*").interrupt();
    // console.log(this.getBoundingClientRect());

    d3.selectAll("text.player-name")
      .transition(t)
      .attr("fill-opacity", 0.2);

    d3.selectAll(`#label-${d.player}`)
      .transition(t)
      .attr("fill-opacity", 1);

    d3.selectAll("circle").style("stroke", "");
    d3.selectAll("circle")
      .transition(t)
      .style("fill-opacity", 0.1);

    d3.selectAll(`#${d.player}`)
      .raise()
      .transition(t)
      .style("fill-opacity", 1)
      .style("stroke", "black");

    let card = d3
      .select("svg")
      .select("g")
      .append("foreignObject")
      .attr("class", "tooltip")
      .attr("height", "2em")
      .attr("width", canvasWidth)
      .attr("x", -margin.left)
      .attr("y", 0 - margin.top)
      .append("xhtml:div")
      .attr("class", "content is-size-6 chart-text");

    card
      .append("p")
      .html(
        `<span class="is-capitalized has-text-weight-semibold">${d.player.replace(
          "_",
          " "
        )}</span> played ${d.nightMatches} night and ${
          d.dayMatches
        } day matches for a total of ${
          d.totalMatches
        } matches played (${Math.round(
          d.nightMatchesPercentage * 100
        )}% nights) on the main court in ${d.year}.`
      );
  }

  handleMouseOut(d, i) {
    d3.selectAll("*").interrupt();
    d3.selectAll("text.player-name")
      .transition(t)
      .attr("fill-opacity", 1);
    d3.selectAll("circle")
      .transition(t)
      .style("fill-opacity", d =>
        selectedPlayers.includes(d.player) ? 0.8 : 0.4
      );
    d3.selectAll("circle")
      .transition(t)
      .style("stroke", "");
    d3.selectAll(".tooltip").remove();
  }

  mountChart(data) {
    const xScale = xScaleGenerator(data, "year");
    const yScale = yScaleGenerator(data, "nightMatchesPercentage");
    const colorScale = colorScaleGenerator(selectedPlayers);

    const canvas = d3
      .select(this.refs.canvas)
      .append("svg")
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr(
        "viewBox",
        `0 0 ${canvasWidth + margin.left + margin.right} ${canvasHeight +
          margin.top +
          margin.bottom}`
      )
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    canvas
      .append("g")
      .attr("class", "axis")
      .attr("transform", `translate(0, ${canvasHeight / 2})`)
      .call(d3.axisBottom(xScale));

    canvas
      .append("g")
      .attr("class", "axis")
      .call(
        d3
          .axisLeft(yScale)
          .tickValues([-1, 0, 1])
          .tickFormat(d3.format(".0%"))
      );

    canvas
      .append("text")
      .attr("class", "chart-text")
      .attr("transform", `translate(${canvasWidth + 5}, ${canvasHeight / 2})`)
      .style("alignment-baseline", "central")
      .text("Year");

    canvas
      .append("text")
      .attr("class", "chart-text is-size-7")
      .style("fill", colors["night-4"])
      .attr(
        "transform",
        `translate(${canvasWidth + margin.right}, ${-margin.top / 1.2})`
      )
      .style("text-anchor", "end")
      .text("Bubble size indicate total matches played");

    canvas
      .append("text")
      .attr("class", "chart-text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left / 1.5)
      .attr("x", 0)
      .style("text-anchor", "end")
      .text("Mainly nights");

    canvas
      .append("text")
      .attr("class", "chart-text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left / 1.5)
      .attr("x", 0 - canvasHeight / 2)
      .style("text-anchor", "middle")
      .text("Equal days and nights");

    canvas
      .append("text")
      .attr("class", "chart-text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left / 1.5)
      .attr("x", 0 - canvasHeight)
      .style("text-anchor", "start")
      .text("Mainly days");

    let circles = canvas
      .selectAll("circle")
      .data(data)
      .enter()
      .append("g");

    circles
      .append("circle")
      .attr("id", d => `${d.player}`)
      .on("mouseover", this.handleMouseOver)
      .on("mouseout", this.handleMouseOut)
      .attr("r", d => (d.totalMatches > 0 ? d.totalMatches * 3 : 0))
      .attr("cx", d =>
        selectedPlayers.includes(d.player)
          ? xScale(d.year)
          : xScale(d.year) - jitterWidth / 2 + Math.random() * jitterWidth
      )
      .attr("cy", d =>
        selectedPlayers.includes(d.player)
          ? yScale(d.nightMatchesPercentage - d.dayMatchesPercentage)
          : yScale(d.nightMatchesPercentage - d.dayMatchesPercentage) -
            jitterHeight / 2 +
            Math.random() * jitterHeight
      )
      .style("fill", d =>
        selectedPlayers.includes(d.player)
          ? colorScale(d.player)
          : colors["night-4"]
      )
      .style("fill-opacity", d =>
        selectedPlayers.includes(d.player) ? 0.8 : 0.4
      );

    // add player name.
    circles
      .data(
        data.filter(d => selectPlayerYear.includes(`${d.player}_${d.year}`))
      )
      .append("text")
      .style("fill", d => colorScale(d.player))
      .attr("class", "player-name is-capitalized chart-text is-size-7")
      .attr("id", d => `label-${d.player}`)
      .attr("x", d => xScale(d.year) + 10)
      .attr(
        "y",
        d => yScale(d.nightMatchesPercentage - d.dayMatchesPercentage) - 20
      )
      .text(d => d.player.replace("_", " "))
      .raise();
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="container">
            <h1 className="title">
              Does Roger Federer play more night matches?
            </h1>
            <p className="subtitle is-6">
              Australian Open main court singles matches 2009 to 2019. Data from{" "}
              <a href="https://en.wikipedia.org/wiki/2018_Australian_Open_%E2%80%93_Day-by-day_summaries">
                Wikipedia's day by day summaries
              </a>
              . See this{" "}
              <a href="https://gitlab.com/picturatechnica/picturatechnica/-/blob/9186caf13ba114fd35f4096378191280f32e8e47/src/components/viz/tennis.ipynb">
                Python Notebook
              </a>{" "}
              to get this data yourself.
            </p>
            <div className="columns box">
              <div
                className="column is-centered svg-container"
                ref="canvas"
              ></div>
            </div>
            <p className="is-italic is-size-7 has-text-danger">
              Best viewed on a desktop.
            </p>
          </div>
        </section>
      </>
    );
  }
}

export default Tennis;
