import React from "react";
import Button from "../main/button";
import img from "../../images/pictura-technica-rwc-2019.png";
import video from "../../images/pictura-technica-rwc-2019.mp4";

const Content = props => {
  switch (props.contentType) {
    case "image":
      return (
        <img src={img} alt="Rugby World Cup 2019 Pool Matches - Net Points" />
      );
    case "video":
      return (
        <video loop autoPlay>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    default:
      return (
        <img src={img} alt="Rugby World Cup 2019 Pool Matches - Net Points" />
      );
  }
};

class Rugby extends React.Component {
  state = { contentType: "image", buttonLabel: "Animate" };

  updateContent = () => {
    let content, button;
    switch (this.state.contentType) {
      case "image":
        content = "video";
        button = "Stop";
        break;
      case "video":
        content = "image";
        button = "Animate";
        break;
      default:
        content = "image";
        button = "Animate";
    }
    this.setState({ contentType: content, buttonLabel: button });
  };

  render() {
    return (
      <>
        <section className="section">
          <div className="container">
            <h1 className="title">Rugby World Cup 2019 - Pool Matches</h1>
            <p className="subtitle is-6">
              Net points (+/-) of each team after completion of the pool
              matches. Data from{" "}
              <a href="https://www.rugbyworldcup.com/matches">
                https://www.rugbyworldcup.com/matches
              </a>
              .
            </p>
            <div className="box">
              <div className="columns">
                <div className="column">
                  <Button
                    onClickHandler={this.updateContent}
                    size="normal"
                    label={this.state.buttonLabel}
                    switchable={true}
                    alignment="right"
                  />
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column-is-half">
                  <figure style={{ minHeight: "20vh" }}>
                    <Content contentType={this.state.contentType} />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Rugby;
