import React, { Component } from "react";

class Sidebar extends Component {
  render() {
    const { text, sideBarLabel } = this.props;
    return (
      <>
        <aside className="menu">
          <p className="menu-label">{sideBarLabel}</p>
        </aside>
        <div className="column">
          <p className="subtitle is-1">{text}</p>
        </div>
      </>
    );
  }
}

export default Sidebar;
