import React from "react";
import animated from "../../images/covid-19.gif";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

const Content = props => {
  switch (props.contentType) {
    default:
      return (
        <img
          src={animated}
          alt="Rugby World Cup 2019 Pool Matches - Net Points"
        />
      );
  }
};

const codeString = `library(readr)
library(ggplot2)
library(dplyr)
library(tidyr)
library(janitor)
library(gganimate)
library(scales)

data <- read_csv("https://raw.githubusercontent.com/CSSEGISandData/COVID-19/master/csse_covid_19_data/csse_covid_19_time_series/time_series_19-covid-Confirmed.csv")
data <- clean_names(data)
data <- data %>% 
  filter(country_region == "New Zealand") %>%
  select(-c(country_region, province_state, lat, long)) %>%
  gather(date, value) %>%
  bind_rows(data.frame(date = "x3_20_20", value = 39)) %>%
  mutate(date = as.Date(date, format = "x%m_%d_%y")) %>%
  filter(date >= '2020-02-01')

data <- data %>%
  mutate(
    rank  = rank(value),
    check = lag(rank),
    keep  = ifelse(check == rank, NA, value)
  )

set_max <- ifelse(max(data$value) %% 2 > 0, max(data$value) + 1, max(data$value)) 
c_pal <- viridis::plasma(n = length(unique(data$keep)))

animated <-
  ggplot(data, aes(date, value)) +
    geom_line(aes(color = value))+
    geom_point(aes(color = value, size = value)) +
    geom_text(aes(label = value), vjust = -1.5, color = "black", size = 3) +
    scale_y_continuous(limits = c(0, set_max), breaks = pretty_breaks()) +
    scale_x_date(date_breaks = "7 days", date_labels = "%b %d") +
    scale_color_gradientn(colors = c_pal[-length(c_pal)]) +
    theme_minimal() +
    theme(
      text = element_text(family = "Roboto Condensed"), 
      plot.caption = element_text(family = "Frank Ruhl Libre", face = "bold"),
      legend.position = "none"
    ) +
    labs(
      x = "",
      y = "",
      title = "New Zealand COVID-19 cases by date ({format(frame_along, '%b %d, %Y')})",
      caption = "www.picturatechnica.com"
    ) + 
    transition_reveal(date) +
    ease_aes("cubic-in-out")
    
animate(
  animated, 
  nframes = nrow(data), 
  fps = 10,
  end_pause = 70 - nrow(data),
  device = "png",
  width = 1000,
  height = 800,
  res = 166
)
`;

class Covid19 extends React.Component {
  state = { contentType: "image" };

  render() {
    return (
      <>
        <section className="section">
          <div className="container">
            <h1 className="title">COVID-19</h1>
            <p className="subtitle is-6">
              Growth in the number of confirmed New Zealand COVID-19 cases over
              time. Data from Johns Hopkins CSSE.
            </p>
            <div className="box">
              <div className="columns is-centered">
                <div className="column-is-half">
                  <figure style={{ minHeight: "20vh" }}>
                    <Content contentType={this.state.contentType} />
                  </figure>
                </div>
              </div>
            </div>
            <div className="box">
              <p>
                <SyntaxHighlighter language="r">{codeString}</SyntaxHighlighter>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Covid19;
