import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import "./App.scss";
import Navigation from "./components/navigation/navigation";
import Tennis from "./components/viz/tennis";
import Covid19 from "./components/viz/covid-19";
import Rugby from "./components/viz/rugby";
import Bar from "./components/viz/bar";
import Sunburst from "./components/viz/sunburst";
import Home from "./components/main/home";
import NotFound from "./components/main/notFound";
import ContentPost from "./components/posts/post-1";

ReactGA.initialize("UA-108258970-2");

class Content extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/tennis" component={Tennis} />
          <Route path="/covid-19" component={Covid19} />
          <Route path="/rugby" component={Rugby} />
          <Route path="/bar" component={Bar} />
          <Route path="/sunburst" component={Sunburst} />
          <Route path="/prefect-handbook" component={ContentPost} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    );
  }
}

function App() {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
      <div className="container">
        <Navigation />
        <Content />
      </div>
    </>
  );
}

export default App;
