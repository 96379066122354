import React from "react";

export default class Button extends React.Component {
  state = { buttonClicked: false };

  changeButton = () => {
    this.setState({ buttonClicked: !this.state.buttonClicked });
  };

  render() {
    const { size, label, alignment, switchable } = this.props;
    const buttonColor = this.state.buttonClicked
      ? "is-danger"
      : "has-text-dark is-success";

    const buttonHandler =
      (this.props.onClickHandler && this.props.onClickHandler) || (() => {});

    return (
      <button
        className={`button ${(size && `is-${size}`) ||
          "is-small"} ${(alignment && `is-pulled-${alignment}`) || ""} ${
          switchable ? buttonColor : "is-warning"
        }`}
        onClick={e => {
          e.preventDefault();
          buttonHandler();
          this.changeButton();
        }}
      >
        {(label && label) || "Update Data"}
      </button>
    );
  }
}
