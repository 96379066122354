import React from "react";

const Home = () => (
  <section className="section">
    <div className="container content">
      <p>
        <span className="is-family-secondary has-text-weight-bold">
          Pictura
        </span>{" "}
        — picture.{" "}
        <span className="is-family-secondary has-text-weight-bold">
          Technica
        </span>{" "}
        — technical. Visualisations of data and other data related topics.
      </p>
      <h1 className="title is-family-secondary">Engineering</h1>
      <p>
        <a href="https://dbt.picturatechnica.com">
          A deck on building an analytics data warehouse with dbt.
        </a>{" "}
        <span className="tag">Analytics / Data Engineering</span>
      </p>
      <p>
        <a href="/prefect-handbook">
          A beginners handbook to scheduling workflows (tasks, jobs) with
          Prefect (Airflow like).
        </a>{" "}
        <span className="tag">Prefect, Python, Data Engineering</span>
      </p>
      <h1 className="title is-family-secondary">Visualisations/Tools</h1>
      <p>
        <a href="https://gitlab.com/jeremyyeo/arcanum">
          Arcanum — A CLI application and Python module for data obfuscation.
        </a>{" "}
        <span className="tag">Python</span>
      </p>
      <p>
        <a href="https://jeremyyeo.shinyapps.io/machina/">
          Machina — A collection of analytic tools (forecasting, causal impact
          analysis) in an R Shiny dashboard.
        </a>{" "}
        <span className="tag">R, AWS, Docker</span>
      </p>
      <p>
        <a href="/covid-19">
          Growth in confirmed New Zealand COVID-19 cases over time.
        </a>{" "}
        <span className="tag">R</span>
      </p>
      <p>
        <a href="/tennis">
          Does Roger Federer play more night matches in the Australian Open?
        </a>{" "}
        <span className="tag">D3.js, Python</span>
      </p>
      <p>
        <a href="/rugby">
          Rugby World Cup 2019 net points of each team after completion of the
          pool matches.
        </a>{" "}
        <span className="tag">R</span>
      </p>
      <p>
        <a href="http://taputapu.jeremyyeo.com/usecumulator">
          A calculator to figure out the optimal use or accumulate strategies
          for the AA Smartfuel discount program.
        </a>
        <span className="tag">JavaScript</span>
      </p>
      <p>
        <a href="http://www.jeremyyeo.com/shiny/social-cocoon/">
          A real-time (no longer the case) Twitter sentiment analysis dashboard.
        </a>
        <span className="tag">R, AWS</span>
      </p>
      <h1 className="title is-family-secondary">Experiments</h1>
      <p>
        <a href="/bar">A bar chart with smooth transitions on update.</a>{" "}
        <span className="tag">D3</span>
      </p>
    </div>
  </section>
);

export default Home;
