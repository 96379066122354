import React from "react";

const NotFound = () => (
  <section className="section">
    <div className="container content">
      This is not what you are looking for.
    </div>
  </section>
);

export default NotFound;
