import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import source from "./prefect-handbook.md";
import CodeBlock from "../../utils/CodeBlock";

class ContentPost extends React.Component {
  state = { content: null };

  componentDidMount() {
    fetch(source)
      .then((resp) => resp.text())
      .then((text) => {
        this.setState({ content: text });
      });
  }

  render() {
    return (
      <>
        <section className="section">
          <div className="container content">
            <ReactMarkdown
              source={this.state.content}
              escapeHtml={false}
              renderers={{ code: CodeBlock }}
            />
          </div>
        </section>
      </>
    );
  }
}

export default ContentPost;
