import React, { Component } from "react";
import logo from "../../logo.png";

class Navigation extends Component {
  render() {
    return (
      <nav
        className="navbar is-transparent is-family-secondary has-shadow has-text-weight-semibold"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={logo} alt="logo" width="112" height="28" />
            </a>
            <a
              className="navbar-item is-hidden-desktop"
              href="https://www.linkedin.com/in/jeremyyeo"
            >
              LinkedIn
            </a>
            <a
              className="navbar-item is-hidden-desktop"
              href="https://github.com/jeremyyeo"
            >
              GitHub
            </a>
            <a
              className="navbar-item is-hidden-desktop"
              href="https://gitlab.com/jeremyyeo"
            >
              GitLab
            </a>
          </div>
          <div className="navbar-menu" id="nav">
            <div className="navbar-start"></div>
            <div className="navbar-end">
              <a
                className="navbar-item"
                href="https://www.linkedin.com/in/jeremyyeo"
              >
                LinkedIn
              </a>
              <a className="navbar-item" href="https://github.com/jeremyyeo">
                GitHub
              </a>
              <a className="navbar-item" href="https://gitlab.com/jeremyyeo">
                GitLab
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
